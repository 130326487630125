.App {
  text-align: center;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}
.app-loader-outer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
