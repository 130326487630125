.textbox-container {
  min-height: 140px;
  min-width: 120px;
  display: flex;
  flex-direction: column;
  text-align: left;
}
.textbox {
  height: 100px;
  resize: none;
  outline: none;
  border-radius: 4px;
  padding: 5px 5px 5px 10px;
  border: 2px solid #ccc;
}
.textbox:focus {
  border: 2px solid #1c1ca7;
}
.textbox.error {
  border-color: red;
}
.errortext {
  color: red;
  width: 100%;
  margin: 0;
  font-size: 11px;
}
.textbox-label {
  font-size: 12px;
  color: #565656;
}
textarea:disabled {
  opacity: 0.8;
  cursor: not-allowed;
}
