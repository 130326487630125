.providers-container {
  width: calc(100vw - 50px);
  margin: 0 auto;
  padding: 150px 40px 0;
  display: inline-flex;
  flex-direction: column;
  height: calc(100vh - 150px);
  overflow-y: auto;
  overflow-x: hidden;
}
.provider-title-container {
  color: #1c1ca7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  font-weight: 600;
}
.title {
  color: #1c1ca7;
  font-size: 24px;
  text-transform: capitalize;
}
.provider-detail {
  width: 540px;
  text-align: left;
  margin: 0 auto;
  position: relative;
}
.add-text {
  cursor: pointer;
}
.provider-form-container {
  width: 400px;
  margin: auto;
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 30%);
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px 20px 50px;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: 1;
  align-items: center;
  justify-content: center;
}
.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.modal-container {
  width: 300px;
  background-color: #fff;
  border-radius: 6px;
  align-items: center;
}
.modal-header {
  height: 40px;
  text-align: left;
  border-bottom: 1px solid #ccc;
  display: flex;
  align-items: center;
  padding: 0 20px;
}
.modal-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px;
}
