.checkbox-container {
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: flex-start;
  padding-bottom: 20px;
}
.errortext {
  color: red;
  width: 100%;
  margin: 0;
  font-size: 11px;
}
.input-label {
  font-size: 12px;
  color: #565656;
}
.checkbox {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding-bottom: 5px;
}
.hidden-checkbox-input {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
}
.checkbox-label {
  font-size: 14px;
  cursor: pointer;
}
