.provider-card {
  border-radius: 6px;
  min-height: 150px;
  width: 100%;
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 30%);
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  cursor: pointer;
}
.providerview-card {
  border-radius: 6px;
  width: 500px;
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 30%);
  margin: 30px auto;
  text-align: left;
  padding: 20px;
  position: relative;
}
.provider-logo {
  width: 150px;
  height: 100%;
  background-color: rgb(28, 28, 167, 0.1);
  color: #1c1ca7;
  font-size: 90px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}
.providerview-logo {
  width: 120px;
  height: 120px;
  background-color: rgb(28, 28, 167, 0.1);
  color: #1c1ca7;
  font-size: 80px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  text-transform: uppercase;
}
.provider-info-container {
  display: flex;
  padding: 5px 0px;
  width: calc(100% - 150px);
  position: relative;
  height: 100%;
}
.providerview-details {
  padding-bottom: 30px;
}
.provider-info-left-container,
.provider-info-right-container {
  padding: 15px;
  width: 50%;
  display: flex;
  flex-direction: column;
}
.provider-info-container p {
  margin: 0;
  line-height: 22px;
  font-size: 14px;
  text-transform: capitalize;
}
.provider-info-container p.email {
  text-transform: none;
}
.provider-edit-text {
  position: absolute;
  bottom: 20px;
  right: 20px;
  padding: 0 5px;
  font-size: 14px;
  color: #1c1ac7;
  font-weight: 600;
  cursor: pointer;
}
.providerview-details .provider-edit-text {
  bottom: 20px;
}
.providerview-details p {
  text-transform: capitalize;
}
.providerview-details p.email {
  text-transform: none;
}
.provider-delete-text {
  position: absolute;
  color: red;
  cursor: pointer;
  right: 20px;
  top: 20px;
  font-size: 11px;
}
