.auth-container {
  margin-top: 150px;
}

.authhistory-container {
  border-radius: 6px;
  width: 350px;
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 30%);
  margin: 30px auto;
  text-align: left;
  padding: 20px;
  position: relative;
}
.authhistory-title {
  font-size: 18px;
  color: #333333;
  font-weight: 600;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.add-text {
  color: #1c1ca7;
  font-size: 14px;
  cursor: pointer;
}
.history-item {
  font-size: 14px;
  color: #565656;
  line-height: 22px;
  margin-bottom: 15px;
}
.history-id {
  color: #1c1ca7;
  text-decoration: none;
  cursor: pointer;
  padding-right: 10px;
}
.history-id:hover {
  text-decoration: underline;
}
.history-date {
  margin: 0;
}
.authdetail-container {
  margin: 150px auto 30px;
  max-width: 500px;
  padding: 20px 20px 40px;
  box-shadow: 0 2px 8px 1px rgb(0 0 0 / 20%);
  border-radius: 8px;
  position: relative;
}
.auth-info {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.authdetail-title {
  color: #1c1ca7;
  font-size: 24px;
  text-align: left;
  font-weight: 600;
}
.auth-info-text {
  font-size: 16px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 15px 0;
}
.auth-info-text b {
  width: 150px;
}
.auth-info-text span {
  width: calc(100% - 250px);
  text-transform: capitalize;
}
.auth-info-text a {
  color: #1c1ac7;
  text-decoration: none;
}
.auth-info-text a:hover {
  text-decoration: underline;
}
.auth-edit-text {
  position: absolute;
  bottom: 20px;
  right: 20px;
  padding: 0 20px;
  font-size: 14px;
  color: #1c1ac7;
  font-weight: 600;
  cursor: pointer;
}
.Auth-form-container {
  width: 400px;
  margin: 150px auto 30px;
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 20%);
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px 20px 50px;
}
