.input-container {
  min-height: 60px;
  min-width: 120px;
  display: flex;
  flex-direction: column;
  text-align: left;
}
.input {
  min-height: 30px;
  width: 200px;
  border-radius: 4px;
  border: 2px solid #ccc;
  outline: none;
  padding: 0 5px 0 10px;
}
.input:focus {
  border: 2px solid #1c1ca7;
}
.input.error {
  border-color: red;
}
.errortext {
  color: red;
  width: 100%;
  margin: 0;
  font-size: 11px;
}
.input-label {
  font-size: 12px;
  color: #565656;
}
input:disabled {
  opacity: 0.8;
  cursor: not-allowed;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
