.button {
  width: 200px;
  border: 1px solid #1c1ca7;
  height: 30px;
  border-radius: 4px;
  cursor: pointer;
  text-transform: capitalize;
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.primary {
  background: #1c1ca7;
  color: #fff;
}
.primary:hover {
  background: #fff;
  color: #1c1ca7;
}
.secondary {
  background: #fff;
  color: #1c1ca7;
}
.secondary:hover {
  background: #1c1ca7;
  color: #fff;
}
