.members-container {
  width: 100vw;
  margin: auto;
  padding: 150px 0px 0;
  display: inline-flex;
  flex-direction: column;
  height: calc(100vh - 150px);
  overflow-y: auto;
  overflow-x: hidden;
}
.title-container {
  color: #1c1ca7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  font-weight: 600;
  padding: 0 20px;
}
.title {
  color: #1c1ca7;
  font-size: 24px;
  text-transform: capitalize;
}
.member-detail {
  width: 540px;
  text-align: left;
  margin: 0 auto;
  position: relative;
}
.add-text {
  cursor: pointer;
}
.member-form-container {
  width: 400px;
  margin: auto;
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 30%);
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px 20px 50px;
}
.benificiary-title {
  font-size: 12px;
  text-align: left;
  color: #1c1ca7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
}
.benificiary-label {
  font-size: 12px;
  color: #565656;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.add-benificiary {
  cursor: pointer;
  color: #1c1ca7;
  font-weight: 600;
}
.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
