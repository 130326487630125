.dropdown-container {
  min-height: 60px;
  min-width: 120px;
  display: flex;
  flex-direction: column;
  text-align: left;
}
.dropdown {
  min-height: 34px;
  min-width: 200px;
  width: 100%;
  border-radius: 4px;
  border: 2px solid #ccc;
  outline: none;
  padding: 0 5px 0 10px;
}
.dropdown:focus {
  border: 2px solid #1c1ca7;
}
.dropdown.error {
  border-color: red;
}
.errortext {
  color: red;
  width: 100%;
  margin: 0;
  font-size: 11px;
}
.dropdown-label {
  font-size: 12px;
  color: #565656;
}
select:disabled {
  opacity: 0.8;
  cursor: not-allowed;
}
