.member-card {
  border-radius: 6px;
  height: 150px;
  width: calc(100% - 40px);
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 30%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  cursor: pointer;
  margin: 0 20px 30px;
}
.memberview-card {
  border-radius: 6px;
  width: 500px;
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 30%);
  margin: 30px auto;
  text-align: left;
  padding: 20px;
  position: relative;
}
.member-logo {
  width: 150px;
  height: 100%;
  background-color: rgb(28, 28, 167, 0.1);
  color: #1c1ca7;
  font-size: 90px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}
.memberview-logo {
  width: 120px;
  height: 120px;
  background-color: rgb(28, 28, 167, 0.1);
  color: #1c1ca7;
  font-size: 80px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  text-transform: uppercase;
}
.info-container {
  display: flex;
  padding: 5px 0px;
  width: calc(100% - 150px);
  position: relative;
  text-transform: capitalize;
}
.memberview-details {
  padding-bottom: 30px;
  text-transform: capitalize;
}
.info-left-container,
.info-right-container {
  padding: 0 15px;
  width: 50%;
}
.info-container .member-info-text {
  margin: 0;
  line-height: 22px;
  font-size: 14px;
}

.edit-text {
  position: absolute;
  bottom: 10px;
  right: 0px;
  padding: 0 20px;
  font-size: 14px;
  color: #1c1ac7;
  font-weight: 600;
  cursor: pointer;
}
.memberview-details .edit-text {
  bottom: 20px;
}
.memberview-details .member-info-text {
  margin: 15px 0;
}
.delete-text {
  position: absolute;
  color: red;
  cursor: pointer;
  right: 20px;
  top: 10px;
  font-size: 11px;
}
