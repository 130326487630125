.secondary-header {
  position: fixed;
  top: 50px;
  height: 50px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #b3b3b3;
  background-color: #ffffff;
  z-index: 1;
  padding: 0 15px;
}
.secondary-header-left-container {
  width: calc(100% - 300px);
  height: 100%;
  display: flex;
  align-items: center;
}
.secondary-header-right-container {
  height: 100%;
  display: flex;
  align-items: center;
}
.secondary-header-item {
  border-bottom: 2px solid #fff;
  height: 99%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  font-weight: 600;
  cursor: pointer;
}
.secondary-header-item.selected {
  border-bottom: 2px solid #1c1ca7;
  color: #1c1ca7;
}
.searchbox {
  width: 200px;
  margin: 0 30px 0 0;
}
.searchbox .input-container {
  min-height: auto;
}
