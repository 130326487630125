.login-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.login-card {
  width: 350px;
  height: 70vh;
  border-radius: 8px;
  display: flex;
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 30%);
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.loginbutton {
  width: 215px;
  border: 1px solid #1c1ca7;
  background: #fff;
  height: 30px;
  border-radius: 4px;
  color: #1c1ca7;
  cursor: pointer;
}
.loginbutton:hover {
  background: #1c1ca7;
  color: #fff;
}
