.header {
  display: flex;
  padding: 0 10px;
  height: 50px;
  border-bottom: 1px solid #1c1ca7;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #1c1ca7;
  z-index: 1;
}
.navbar {
  display: flex;
  align-items: center;
  height: 100%;
}
.navbar-item {
  color: #fff;
  display: flex;
  height: 100%;
  align-items: center;
}

.navbar-item h2 {
  padding: 0 15px;
}
.navbar-link {
  text-decoration: none;
  color: #fff;
  font-weight: 600;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 15px;
}
.navbar-item.selected .navbar-link {
  border-bottom: 2px solid #fff;
}
.logout {
  text-decoration: none;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
}
